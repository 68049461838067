import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import {AxiosResponse} from 'axios'

import am4geodata_worldHigh from '@amcharts/amcharts4-geodata/worldHigh'
import lang from '@amcharts/amcharts4-geodata/lang/DE'
import {
  tooltipStyleObject,
  legend,
  getCurYear,
  getPrevYear,
  getCurMonth,
  getQuarterLabel,
  Month,
} from '../../am4charts'
import { StateInterface } from '../../UserContext'
import API from '../../API'
import {tooltipCountry} from './W8Tooltips'

const EU_COUNTRIES = ['BE', 'BG', 'DK', 'DE', 'EE', 'FI', 'FR', 'GR', 'IE', 'IT', 'MR', 'LV', 'LT', 'LU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SM', 'SE', 'SK', 'SI', 'IT', 'CZ', 'HU', 'GB', 'VA', 'FO', 'CY']

interface IW4Entry {
  country: string
  amount: number
}

interface IW4DisplayData {
  year: number
  month: number
  yearly: Array<IW4Entry>
  quarterly: Array<IW4Entry>
  monthly: Array<IW4Entry>
}

interface IServerResponse {
  data: IW4DisplayData
}

enum periods {
  yearly,
  quarterly,
  monthly,
}

const fetchWidgetData = async (
  previousYear: boolean,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
): Promise<IW4DisplayData> => {
  const url = `/providers/${context.provider}/w8?previousYear=${previousYear}`
  try {
    return API.get(url).then((resp: AxiosResponse<IServerResponse>) => resp.data.data)
  } catch {
    if (!context.error) {
      setContext({error: true})
    }
    return {
      year: getCurYear(),
      month: getCurMonth(),
      yearly: [],
      quarterly: [],
      monthly: [],
    }
  }
}

export const W8ChartInit = async (
  chartId: string,
  previousYear: boolean,
  range: periods,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
): Promise<am4core.Sprite> => {
  const data = await fetchWidgetData(previousYear, context, setContext)
  // @ts-ignore
  const rangeData: Array<IW4Entry> = data[periods[range]]
  const countryData = rangeData.map((entry) => ({ ...entry, id: entry.country }))

  const euDataCountries = countryData.map(({country}) => country).filter((id) => EU_COUNTRIES.includes(id))
  const worldDataCountries = countryData.map(({country}) => country).filter((id) => !EU_COUNTRIES.includes(id))

  const tooltipSecondaryText = {
    monthly: Month[data.month],
    quarterly: getQuarterLabel(data.month, data.year),
    yearly: '',
  }

  const tooltipHTML = tooltipCountry(
    range,
    previousYear ? data.year - 1 : data.year,
    // @ts-ignore
    tooltipSecondaryText[periods[range]],
  )

  const euZone = {
    name: 'EU',
    type: 'MapPolygonSeries',
    useGeodata: true,
    include: euDataCountries,
    exclude: worldDataCountries,
    mapPolygons: {
      tooltipHTML,
      fill: '#007cc8',
    },
    tooltip: { ...tooltipStyleObject },
    fill: '#007cc8',
    data: countryData.filter((entry) => EU_COUNTRIES.includes(entry.country)),
  }

  const worldZone = {
    name: 'Weltzone',
    type: 'MapPolygonSeries',
    useGeodata: true,
    include: worldDataCountries,
    exclude: euDataCountries,
    mapPolygons: {
      tooltipHTML,
      fill: '#4ca3d8',
    },
    tooltip: { ...tooltipStyleObject },
    fill: '#4ca3d8',
    data: countryData,
  }

  const chart = am4core.createFromConfig({
    geodata: am4geodata_worldHigh,
    geodataNames: lang,
    projection: new am4maps.projections.Miller(),
    homeGeoPoint: {
      latitude: 50.6,
      longitude: 10.2,
    },
    homeZoomLevel: 7,
    series: [
      {
        type: 'MapPolygonSeries',
        useGeodata: true,
        hiddenInLegend: true,
      },
      worldZone,
      euZone,
    ],
    tooltip: {
      ...tooltipStyleObject,
    },
    legend: {
      ...legend,
      valign: 'bottom',
      position: 'bottom',
      background: {
        fill: '#FFFFFF',
      },
    },
  }, chartId, am4maps.MapChart)

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}
