import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {AxiosResponse} from 'axios'
import {Colors, getCurYear, getPrevYear, legend, Month, tooltipStyleObject, XYcursor} from '../../am4charts'
import {StateInterface} from '../../UserContext'
import API from '../../API'
import {tooltipCurYear, tooltipPrevYear} from './W3Tooltips'
import {formatISODate} from '../../utils'

interface IW3Resp {
  year: number
  month: number
  activeContracts: number
  prolongableContracts: number
  terminatingContracts: number
  dataCreated: string
  date?: Date
  tooltip?: ITooltip
}

interface IWidgetData {
  curYear: Array<IW3Resp>
  prevYear: Array<IW3Resp>
}

interface ITooltip {
  [propName: string]: number | string
}

interface IServerResponse {
  data: Array<IW3Resp>
}

const WidgetData: IWidgetData = {
  curYear: [],
  prevYear: [],
}

const fetchWidgetData = async (checked: boolean): Promise<Array<IW3Resp>> => {
  const url = `/w3/?previousYear=${checked}`
  // eslint-disable-next-line no-return-await
  return await API.get(url).then((resp: AxiosResponse<IServerResponse>) => resp.data.data)
}

const getData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  setFooterTitle: (footerTitle: string) => void
) => {
  try {
    const [curYear, prevYear]: Array<Array<IW3Resp>> = await Promise.all([
      fetchWidgetData(false),
      fetchWidgetData(true),
    ])
    WidgetData.curYear = curYear
    WidgetData.prevYear = prevYear

    const dataCreated = curYear[curYear.length - 1].dataCreated
    setFooterTitle(`Stand: ${formatISODate(dataCreated)}`)
  } catch {
    if (!context.error) {
      setContext({error: true})
    }
  }
  return WidgetData
}

export const W3ChartInit = async (
  chartId: string,
  checked: boolean,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  setFooterTitle: (footerTitle: string) => void
): Promise<am4core.Sprite> => {
  await getData(context, setContext, setFooterTitle)
  return checked ? LastYearInit(chartId) : YearInit(chartId)
}

const YearInit = (container: string): am4core.Sprite => {
  const {curYearData} = generateYearData()
  const series = [
    {
      type: 'LineSeries',
      name: 'Verlängerbar',
      dataFields: {
        dateX: 'date',
        valueY: 'prolongableContracts',
      },
      stroke: Colors.greyBlue,
      fill: Colors.greyBlue,
      strokeWidth: 1,
    },
    {
      type: 'LineSeries',
      name: 'Gekündigt',
      dataFields: {
        dateX: 'date',
        valueY: 'terminatingContracts',
      },
      stroke: Colors.telefonie,
      fill: Colors.telefonie,
      strokeWidth: 1,
    },
    {
      type: 'LineSeries',
      name: 'Aktiv',
      data: curYearData,
      dataFields: {
        dateX: 'date',
        valueY: 'activeContracts',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      tooltipHTML: tooltipCurYear,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      autoSetClassName: true,
      data: curYearData,
      series,
      legend: {
        ...legend,
        reverseOrder: true,
      },
      xAxes: [
        {
          type: 'DateAxis',
          dateFormats: {
            month: 'MMM',
          },
          dataFields: {
            category: 'date',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
          },
          startLocation: 0,
          endLocation: 0.48,
        },
      ],
      yAxes: [
        {
          type: 'ValueAxis',
          numberFormatter: {
            numberFormat: '#,###.##  St.',
          },
          cursorTooltipEnabled: false,
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: XYcursor,
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const LastYearInit = (container: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateYearData()
  prevYearData.shift()
  curYearData.shift()

  const customTooltip = {
    ...tooltipStyleObject,
    pointerOrientation: 'down',
  }
  const customLegend = {
    ...legend,
    itemContainers: {
      clickable: false,
      focusable: false,
    },
  }
  const series = [
    {
      type: 'LineSeries',
      data: prevYearData,
      legendSettings: {
        labelText: String(getPrevYear()),
      },
      dataFields: {
        dateX: 'date',
        valueY: 'activeContracts',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      tooltipHTML: tooltipPrevYear,
      tooltip: customTooltip,
      strokeWidth: 1,
    },
    {
      type: 'LineSeries',
      data: curYearData,
      legendSettings: {
        labelText: String(getCurYear()),
      },
      dataFields: {
        dateX: 'date',
        valueY: 'activeContracts',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      strokeWidth: 1,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      series,
      legend: customLegend,
      xAxes: [
        {
          type: 'DateAxis',
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
          },
          startLocation: -1,
          endLocation: 0.48,
        },
      ],
      yAxes: [
        {
          cursorTooltipEnabled: false,
          type: 'ValueAxis',
          min: 0,
          numberFormatter: {
            numberFormat: '#,###.##  St.',
          },
          renderer: {
            opposite: true,
            labels: {
              fill: Colors.brownGrey,
              align: 'right',
            },
          },
        },
      ],
      cursor: XYcursor,
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const generateYearData = () => {
  const prevYearData: Array<IW3Resp> = []
  const curYearData: Array<IW3Resp> = []

  for (let i = 0; i < WidgetData.prevYear.length; i++) {
    prevYearData[i] = WidgetData.prevYear[i]
    prevYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
    prevYearData[i].date = new Date(WidgetData.curYear[i].year, WidgetData.prevYear[i].month)

    if (WidgetData.curYear[i]) {
      curYearData[i] = WidgetData.curYear[i]
      curYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
      curYearData[i].date = new Date(WidgetData.curYear[i].year, WidgetData.curYear[i].month)
    }
  }

  return {prevYearData, curYearData}
}

const generateTooltip = (prevYearData: Array<IW3Resp>, curYearData: Array<IW3Resp>, i: number): ITooltip => ({
  month: Month[prevYearData[i].month],
  prevYear: String(prevYearData[i].year),
  curYear: String(curYearData[i].year),
  prevActiveContracts: prevYearData[i].activeContracts,
  curActiveContracts: curYearData[i].activeContracts,
  prevTerminatingContracts: prevYearData[i].terminatingContracts,
  curTerminatingContracts: curYearData[i].terminatingContracts,
  prevProlongableContracts: prevYearData[i].prolongableContracts,
  curProlongableContracts: curYearData[i].prolongableContracts,
})
