import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {ForceDirectedTree} from '@amcharts/amcharts4/plugins/forceDirected'

import {AxiosResponse} from 'axios'
import {
  Colors,
  legend,
  legendHide,
  Month,
  replaceSeparator,
  tooltipStyleObject,
  XYcursor,
} from '../../am4charts'
import {StateInterface} from '../../UserContext'
import API from '../../API'
import {tooltipCurYear, tooltipPrevYear} from './W5Tooltips'

interface IW5Resp {
  year: number
  month: number
  phoneNumbers: number
  additionalDataPackages: number
  date?: Date
  fontSize?: number
  fixed?: boolean
  x?: any
  y?: any
  yearTitle?: string
  monthTitle?: string
  tooltip?: ITooltip
}

interface IWidgetData {
  curYear: Array<IW5Resp>
  prevYear: Array<IW5Resp>
}

interface ITooltip {
  [propName: string]: number | string
}

interface IServerResponse {
  data: Array<IW5Resp>
}

let WidgetData: IWidgetData = {
  curYear: [],
  prevYear: [],
}

const yAxes = [
  {
    cursorTooltipEnabled: false,
    type: 'ValueAxis',
    min: 0,
    numberFormatter: {
      numberFormat: '#,###.##  Einh',
    },
    renderer: {
      opposite: true,
      labels: {
        fill: Colors.brownGrey,
        align: 'right',
      },
    },
  },
]

const fetchWidgetData = async (context: StateInterface, checked: boolean): Promise<Array<IW5Resp>> => {
  const url = `/providers/${context.provider}/w5/?previousYear=${checked}${context.filter}`
  // eslint-disable-next-line no-return-await
  return await API.get(url).then((resp: AxiosResponse<IServerResponse>) => resp.data.data)
}

const getData = async (
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
) => {
  checkChangeContext(changeContext)
  if (!WidgetData.curYear.length) {
    try {
      const [curYear, prevYear]: Array<Array<IW5Resp>> = await Promise.all([
        fetchWidgetData(context, false),
        fetchWidgetData(context, true),
      ])
      WidgetData.curYear = curYear
      WidgetData.prevYear = prevYear
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }
  return WidgetData
}

const checkChangeContext = (changeContext: boolean) => {
  if (changeContext) {
    WidgetData = {
      curYear: [],
      prevYear: [],
    }
  }
}

export const W5ChartInit = async (
  chartId: string,
  checked: boolean,
  range: number,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
): Promise<am4core.Sprite> => {
  await getData(context, setContext, changeContext)

  switch (range) {
    case 0:
      return YearInit(chartId, checked)
    case 1:
      return QuarterInit(chartId, checked)
    case 2:
      return MonthInit(chartId, checked)
    default:
      return YearInit(chartId, checked)
  }
}

const YearInit = (container: string, checked: boolean): am4core.Sprite => {
  const {prevYearData, curYearData} = generateYearData()
  if (checked) {
    prevYearData.shift()
    curYearData.shift()
  }
  const customLegend = {
    ...legend,
    itemContainers: {
      clickable: false,
      focusable: false,
    },
  }
  const series = [
    {
      type: 'LineSeries',
      data: prevYearData,
      legendSettings: {
        labelText: 'Vorjahr',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'additionalDataPackages',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      tooltipHTML: tooltipPrevYear,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
    {
      type: 'LineSeries',
      data: curYearData,
      legendSettings: {
        labelText: 'Jahresübersicht',
      },
      dataFields: {
        dateX: 'date',
        valueY: 'additionalDataPackages',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      tooltipHTML: !checked && tooltipCurYear,
      tooltip: tooltipStyleObject,
      strokeWidth: 1,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      series: checked ? [...series] : [series[1]],
      legend: checked ? customLegend : legendHide,
      xAxes: [
        {
          type: 'DateAxis',
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
          },
          startLocation: checked ? -1 : 0,
        },
      ],
      yAxes,
      cursor: XYcursor,
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const QuarterInit = (container: string, checked: boolean): am4core.Sprite => {
  const {prevYearData, curYearData} = generateQuarterData()
  const series = [
    {
      type: 'ColumnSeries',
      data: prevYearData,
      legendSettings: {
        labelText: String(prevYearData[0].year),
      },
      dataFields: {
        valueY: 'additionalDataPackages',
        categoryX: 'monthTitle',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip: tooltipStyleObject,
    },
    {
      type: 'ColumnSeries',
      data: curYearData,
      legendSettings: {
        labelText: String(curYearData[0].year),
      },
      dataFields: {
        valueY: 'additionalDataPackages',
        categoryX: 'monthTitle',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: checked ? tooltipPrevYear : tooltipCurYear,
      },
      tooltip: tooltipStyleObject,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'monthTitle',
          },
          renderer: {
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.7,
            paddingBottom: 0,
          },
        },
      ],
      yAxes,
      series: checked ? [...series] : [series[1]],
      legend: checked ? legend : legendHide,
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const MonthInit = (container: string, checked: boolean): am4core.Sprite => {
  const monthData = generateMonthData(checked)
  const series = [
    {
      type: 'ForceDirectedSeries',
      dataFields: {
        value: 'additionalDataPackages',
        name: 'yearTitle',
        fixed: 'fixed',
      },
      nodes: {
        propertyFields: {
          x: 'x',
          y: 'y',
        },
        label: {
          adapter: [
            {
              key: 'html',
              callback: (label: any, target: any) => {
                const maxScale = 1.32
                let scale = 0.66 + 0.33 * (target.dataItem?._dataContext.fontSize ?? 0)
                if (checked && scale > maxScale) {
                  scale = maxScale
                }
                return `<div class="chart-label" style="color: {color};transform: scale(${scale})">
                    <div class="amount">
                        {additionalDataPackages}
                        <span class="amount-prefix">Einh</span>
                    </div>
                    <div class="numbers">{monthTitle}</div>
                </div>`
              },
            },
          ],
        },
        circle: {
          fill: Colors.white,
          strokeWidth: 6.4,
        },
      },
      colors: {
        list: checked ? [Colors.cerulean, '', Colors.purpleyPink] : [Colors.cerulean],
      },
      minRadius: checked ? 50 : 73,
      maxRadius: checked ? 73 : 100,
      centerStrength: 0.4,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      data: checked ? monthData : [monthData[0]],
      series,
    },
    container,
    ForceDirectedTree,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const generateYearData = () => {
  const prevYearData: Array<IW5Resp> = []
  const curYearData: Array<IW5Resp> = []

  for (let i = 0; i < WidgetData.prevYear.length; i++) {
    prevYearData[i] = WidgetData.prevYear[i]
    prevYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
    prevYearData[i].date = new Date(WidgetData.curYear[i].year, Number(WidgetData.prevYear[i].month))

    if (WidgetData.curYear[i]) {
      curYearData[i] = WidgetData.curYear[i]
      curYearData[i].tooltip = generateTooltip(WidgetData.prevYear, WidgetData.curYear, i)
      curYearData[i].date = new Date(WidgetData.curYear[i].year, Number(WidgetData.curYear[i].month))
    }
  }

  return {prevYearData, curYearData}
}

const generateQuarterData = () => {
  const prevYearData: Array<IW5Resp> = []
  const curYearData: Array<IW5Resp> = []

  for (let step = 1; step <= 3; step++) {
    prevYearData.unshift(WidgetData.prevYear[WidgetData.prevYear.length - step])
    curYearData.unshift(WidgetData.curYear[WidgetData.curYear.length - step])
  }

  for (let i = 0; i < prevYearData.length; i++) {
    prevYearData[i].tooltip = generateTooltip(prevYearData, curYearData, i)
    curYearData[i].tooltip = generateTooltip(prevYearData, curYearData, i)
    prevYearData[i].monthTitle = Month[Number(prevYearData[i].month)]
    curYearData[i].monthTitle = Month[Number(curYearData[i].month)]
  }

  return {prevYearData, curYearData}
}

const generateMonthData = (checked: boolean) => {
  const monthData = [
    WidgetData.curYear[WidgetData.curYear.length - 1],
    WidgetData.prevYear[WidgetData.prevYear.length - 1],
  ]

  return monthData.map((item: IW5Resp, index: number) => {
    if (index === 0) {
      item.fontSize = item.additionalDataPackages / monthData[0].additionalDataPackages
      if (checked) {
        item.x = am4core.percent(65)
        item.y = am4core.percent(30)
      } else {
        item.x = am4core.percent(50)
        item.y = am4core.percent(45)
      }
    } else if (index === 1) {
      item.fontSize = item.additionalDataPackages / monthData[1].additionalDataPackages
      if (checked) {
        item.x = am4core.percent(35)
        item.y = am4core.percent(55)
      } else {
        delete item.x
        delete item.y
      }
    }
    item.yearTitle = String(item.year)
    item.monthTitle = Month[Number(item.month)]
    item.fixed = true
    return item
  })
}

const generateTooltip = (prevYearData: Array<IW5Resp>, curYearData: Array<IW5Resp>, i: number): ITooltip => ({
  month: Month[Number(prevYearData[i].month)],
  prevYear: String(prevYearData[i].year),
  curYear: String(curYearData[i].year),
  prevPhoneNumbers: `${prevYearData[i].phoneNumbers} St.`,
  curPhoneNumbers: `${(curYearData[i] && curYearData[i].phoneNumbers) || 0} St.`,
  prevAdditionalDataPackages: `${replaceSeparator(prevYearData[i].additionalDataPackages)}  Einh`,
  curAdditionalDataPackages: `${replaceSeparator(curYearData[i] && curYearData[i].additionalDataPackages) || 0}  Einh`,
  prevIndex: `${
    replaceSeparator(
      (curYearData[i] &&
        prevYearData[i].phoneNumbers &&
        prevYearData[i].additionalDataPackages / prevYearData[i].phoneNumbers) ||
        0,
    ) || 0
  }  Einh`,
  curIndex: `${
    replaceSeparator(
      (curYearData[i] &&
        curYearData[i].phoneNumbers &&
        curYearData[i].additionalDataPackages / curYearData[i].phoneNumbers) ||
        0,
    ) || 0
  }  Einh`,
})
