import React, {useState} from 'react'
import Dashboard from './components/Dashboard/Dashboard'
import UserContextProvider from './UserContext'
import {setSessionToken} from './API'

const App: React.FC<unknown> = () => {
  const [error, setError] = useState(false)
  const dashboard = (
    <UserContextProvider>
      <Dashboard/>
    </UserContextProvider>
  )

  const queryParameters = new URLSearchParams(window.location.search)
  const sessionId = queryParameters.get("session") ?? ''
  setSessionToken(sessionId)
  
  //todo: reimplement again
  // if (event.origin !== window.REACT_APP_IFRAME_PARENT_ORIGIN) {
  //   setError(true)
  // }
  
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    return dashboard
  }

  if (error) {
    return <div className="error-session">Error: invalid parent origin</div>
  }

  return dashboard
}

export default App
