export const tooltipDefault = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthTitle} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Grundgebühr</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.basicFee}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Variable Kosten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #4ca3d8">
                    <div class="dotted">
                       <span>{tooltip.totalVariable}</span>
                    </div>
                 </td>
              </tr>
              <tr class="row-padding">
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Einmalzahlungen</span>
                    </div>
                 </td>
                 <td align="right" style="color: #bfdef1">
                    <div class="dotted">
                       <span>{tooltip.totalOtherExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr></tr>
              <tr>
                 <td class="tooltip-title  pt-10">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" class="pt-10" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipDefaultLastYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.fullExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummern</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCountLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUserLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipVariableLastYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="dotted">
                       <span>{tooltip.totalVariableLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.totalVariable}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummern</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCountLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUserLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipDefaultQuarter = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthTitle} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Grundgebühr</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.basicFee}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Variable Kosten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #4ca3d8">
                    <div class="dotted">
                       <span>{tooltip.totalVariable}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Einmalzahlungen</span>
                    </div>
                 </td>
                 <td align="right" style="color: #bfdef1">
                    <div class="dotted">
                       <span>{tooltip.totalOtherExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr></tr>
              <tr>
                 <td class="tooltip-title pt-10">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" class=" pt-10" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipDefaultQuarterLastYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="dotted">
                       <span>{tooltip.totalVariableLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.totalVariable}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummern</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCountLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUserLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipVariable = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthTitle} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Daten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.totalVariableExpensesData}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Sprache</span>
                    </div>
                 </td>
                 <td align="right" style="color: #4ca3d8">
                    <div class="dotted">
                       <span>{tooltip.totalVariableExpensesCalls}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Sonstiges</span>
                    </div>
                 </td>
                 <td align="right" style="color: #bfdef1">
                    <div class="dotted">
                       <span>{tooltip.totalVariableExpensesOther}</span>
                    </div>
                 </td>
              </tr>
              <tr></tr>
              <tr>
                 <td class="tooltip-title pt-10">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" class=" pt-10" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.totalVariable}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipVariableQuarterYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="left">{tooltip.monthTitle} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="dotted">
                       <span>{tooltip.totalVariableLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.totalVariable}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummern</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCountLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUserLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.fullExpensesPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipCall = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthTitle} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>National</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.totalNationalCallExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>International</span>
                    </div>
                 </td>
                 <td align="right" style="color: #4ca3d8">
                    <div class="dotted">
                       <span>{tooltip.totalInternationalCallExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Roaming</span>
                    </div>
                 </td>
                 <td align="right" style="color: #bfdef1">
                    <div class="dotted">
                       <span>{tooltip.totalRoamingCallExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Roaming Global Standard</span>
                    </div>
                 </td>
                 <td align="right" style="color: #AACBFB">
                    <div class="dotted">
                       <span>{tooltip.totalCallRoamingGlobalExpenses}</span>
                    </div>
                 </td>
              </tr>
              <tr></tr>
              <tr>
                 <td class="tooltip-title pt-10">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" class=" pt-10" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.totalCall}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.callPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

export const tooltipCallLastYear = `
        <table class="tooltip">
           <thead>
              <tr>
                 <td></td>
                 <td align="left">{tooltip.monthLast} <span class="bold">{tooltip.prevYear}</span></td>
                 <td align="left">{tooltip.monthTitle} <span class="bold">{tooltip.curYear}</span></td>
              </tr>
           </thead>
           <tbody>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Gesamtkosten</span>
                    </div>
                 </td>
                 <td align="right" style="color: #d9399d">
                    <div class="dotted">
                       <span>{tooltip.totalCallLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #007cc8">
                    <div class="dotted">
                       <span>{tooltip.totalCall}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Rufnummern</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCountLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.numbersCount}</span>
                    </div>
                 </td>
              </tr>
              <tr>
                 <td class="tooltip-title">
                    <div class="dotted">
                       <span>Kosten i. D. p. Rufnummer</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.callPerUserLast}</span>
                    </div>
                 </td>
                 <td align="right" style="color: #333333">
                    <div class="dotted">
                       <span>{tooltip.callPerUser}</span>
                    </div>
                 </td>
              </tr>
           </tbody>
        </table>`

