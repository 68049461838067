import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import {AxiosResponse} from 'axios'
import {Colors, legendHide, Month, replaceSeparator, tooltipStyleObject, XYcursor} from '../../am4charts'
import {StateInterface} from '../../UserContext'
import API from '../../API'
import {tooltipCurYear, tooltipPrevYear} from './W7Tooltips'

interface IW7Resp {
  year: number
  month: number
  phoneNumbers: number
  usage: number
  allowance: number
  tolerance: number
  date?: Date
  monthTitle?: string
  lineSeriesTop?: number
  lineSeriesBottom?: number
  overAllowance?: number
  underAllowance?: number
  tooltip?: ITooltip
}

interface IWidgetData {
  curYear: Array<IW7Resp>
  prevYear: Array<IW7Resp>
}

interface IServerResponse {
  data: Array<IW7Resp>
}

interface ISeries {
  type: string
  dataFields: {
    dateX: string
    valueY: string
  }
  stroke: string
  fill: string
  strokeWidth: number
  id: string
  tooltipHTML?: any
  tooltip?: any
  data: Array<IW7Resp>
}

interface ITooltip {
  [propName: string]: number | string
}

let WidgetData: IWidgetData = {
  curYear: [],
  prevYear: [],
}

const yAxes = [
  {
    min: 0,
    cursorTooltipEnabled: false,
    type: 'ValueAxis',
    numberFormatter: {
      numberFormat: '#,###.##  MiB',
    },
    renderer: {
      opposite: true,
      labels: {
        fill: Colors.brownGrey,
        align: 'right',
      },
    },
  },
]

const tooltip = {
  ...tooltipStyleObject,
  pointerOrientation: 'right',
}

const fetchWidgetData = async (traffic: string, context: StateInterface, checked: boolean): Promise<Array<IW7Resp>> => {
  const url = `/providers/${context.provider}/w7?previousYear=${checked}&vks=${encodeURIComponent(traffic)}${
    context.filter
  }`
  // eslint-disable-next-line no-return-await
  return await API.get(url).then((resp: AxiosResponse<IServerResponse>) => resp.data.data)
}

const getData = async (
  traffic: string,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
) => {
  checkChangeContext(changeContext)
  if (!WidgetData.curYear || !WidgetData.curYear.length) {
    try {
      const [curYear, prevYear]: Array<Array<IW7Resp>> = await Promise.all([
        fetchWidgetData(traffic, context, false),
        fetchWidgetData(traffic, context, true),
      ])
      WidgetData.curYear = curYear
      WidgetData.prevYear = prevYear
    } catch {
      if (!context.error) {
        setContext({error: true})
      }
    }
  }
  return WidgetData
}

const checkChangeContext = (changeContext: boolean) => {
  if (changeContext) {
    WidgetData = {
      curYear: [],
      prevYear: [],
    }
  }
}

export const W7ChartInit = async (
  chartId: string,
  checked: boolean,
  range: number,
  traffic: string,
  context: StateInterface,
  setContext: {
    (arg: StateInterface | Partial<StateInterface>): void
    (arg: StateInterface | Partial<StateInterface>): void
  },
  changeContext: boolean,
): Promise<am4core.Sprite | null> => {
  await getData(traffic, context, setContext, changeContext)

  if (WidgetData.prevYear === null || WidgetData.curYear === null) {
    return null
  }

  switch (range) {
    case 0:
      return checked ? LastYearInit(chartId, traffic) : YearInit(chartId, traffic)
    case 1:
      return QuarterInit(chartId, checked, traffic)
    case 2:
      return MonthInit(chartId, checked, traffic)
    default:
      return YearInit(chartId, traffic)
  }
}

const YearInit = (container: string, traffic: string): am4core.Sprite => {
  const {curYearData} = generateData(traffic)
  const generateSeries = (): Array<ISeries> => {
    const seriesList: Array<ISeries> = []
    const tooltipObject = (i: number) => {
      if (i % 2 !== 0) {
        return {
          tooltipHTML: tooltipCurYear,
          tooltip,
        }
      }
      return null
    }

    for (let i = 0; i < 12; i++) {
      seriesList.push({
        type: 'LineSeries',
        dataFields: {
          dateX: 'date',
          valueY: 'usage',
        },
        stroke: Colors.rustyRed,
        fill: Colors.rustyRed,
        ...tooltipObject(i),
        strokeWidth: 1,
        id: `cur${i}`,
        data: [curYearData[i], curYearData[i + 1]],
      })
    }
    return seriesList
  }
  const generateAxisRanges = (): Array<any> => {
    const axisRanges = []
    for (let i = 0; i < 12; i++) {
      axisRanges.push({
        endValue: 0,
        contents: {
          stroke: Colors.cerulean,
        },
        series: `cur${i}`,
        value: curYearData[i] && curYearData[i].lineSeriesTop,
      })
    }
    return axisRanges
  }
  const series = [
    ...generateSeries(),
    {
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        dateX: 'date',
        valueY: 'allowance',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      noRisers: true,
    },
    {
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        dateX: 'date',
        valueY: 'lineSeriesTop',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
    {
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        dateX: 'date',
        valueY: 'lineSeriesBottom',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      data: [],
      series,
      legend: legendHide,
      xAxes: [
        {
          type: 'DateAxis',
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
          },
        },
      ],
      yAxes: [
        {
          ...yAxes[0],
          axisRanges: generateAxisRanges(),
        },
      ],
      cursor: XYcursor,
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const LastYearInit = (container: string, traffic: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateDataLastYear(traffic)
  const series = [
    {
      type: 'ColumnSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'underAllowance',
        categoryX: 'monthTitle',
      },
      stroke: Colors.purpleyPink,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
    },
    {
      type: 'ColumnSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'overAllowance',
        categoryX: 'monthTitle',
      },
      stroke: Colors.blueGrey,
      fill: Colors.blueGrey,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
      stacked: true,
    },
    {
      type: 'ColumnSeries',
      data: curYearData,
      dataFields: {
        valueY: 'underAllowance',
        categoryX: 'monthTitle',
      },
      stroke: Colors.cerulean,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
    },
    {
      type: 'ColumnSeries',
      data: curYearData,
      dataFields: {
        valueY: 'overAllowance',
        categoryX: 'monthTitle',
      },
      stroke: Colors.rustyRed,
      fill: Colors.rustyRed,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
      stacked: true,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: [
        {
          type: 'CategoryAxis',
          dataFields: {
            category: 'monthTitle',
          },
          renderer: {
            minGridDistance: 50,
            grid: {
              location: 0,
              strokeWidth: 0,
            },
            labels: {
              fill: Colors.brownGrey,
            },
            cellStartLocation: 0.3,
            cellEndLocation: 0.7,
          },
          baseInterval: {
            timeUnit: 'month',
            count: 1,
          },
        },
      ],
      yAxes,
      series,
      legend: legendHide,
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const QuarterInit = (container: string, checked: boolean, traffic: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateData(traffic, 3)
  const xAxes = [
    {
      id: 'firstCategoryAxis',
      type: 'CategoryAxis',
      dataFields: {
        category: 'monthTitle',
      },
      renderer: {
        minGridDistance: 1,
        grid: {
          location: 0,
          strokeWidth: 0,
        },
        labels: {
          fill: Colors.brownGrey,
        },
        cellStartLocation: checked ? 0.18 : 0.35,
        cellEndLocation: checked ? 0.82 : 0.65,
        paddingBottom: 0,
      },
      marginRight: checked ? new am4core.Percent(2) : null,
    },
    {
      id: 'secondCategoryAxis',
      type: 'CategoryAxis',
      dataFields: {
        category: 'monthTitle',
      },
      renderer: {
        minGridDistance: 1,
        grid: {
          location: 0,
          strokeWidth: 0,
        },
        labels: {
          fill: Colors.brownGrey,
        },
        cellStartLocation: 0.18,
        cellEndLocation: 0.82,
        paddingBottom: 0,
      },
      marginLeft: new am4core.Percent(2),
    },
  ]
  const series = [
    {
      xAxis: 'firstCategoryAxis',
      type: 'ColumnSeries',
      data: curYearData,
      dataFields: {
        valueY: 'underAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: checked ? tooltipPrevYear : tooltipCurYear,
      },
      tooltip,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'ColumnSeries',
      data: curYearData,
      dataFields: {
        valueY: 'overAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.rustyRed,
      columns: {
        tooltipHTML: checked ? tooltipPrevYear : tooltipCurYear,
      },
      tooltip,
      stacked: true,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        valueY: 'allowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      noRisers: true,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        valueY: 'lineSeriesTop',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        valueY: 'lineSeriesBottom',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'ColumnSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'underAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'ColumnSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'overAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.blueGrey,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
      stacked: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'StepLineSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'allowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      noRisers: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'StepLineSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'lineSeriesTop',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'StepLineSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'lineSeriesBottom',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: checked ? xAxes : [xAxes[0]],
      yAxes,
      series: checked ? series : [series[0], series[1], series[2], series[3], series[4]],
      legend: legendHide,
      bottomAxesContainer: {
        layout: 'horizontal',
        reverseOrder: true,
      },
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const MonthInit = (container: string, checked: boolean, traffic: string): am4core.Sprite => {
  const {prevYearData, curYearData} = generateData(traffic, 1)
  const xAxes = [
    {
      id: 'firstCategoryAxis',
      type: 'CategoryAxis',
      dataFields: {
        category: 'monthTitle',
      },
      renderer: {
        minGridDistance: 1,
        grid: {
          location: 0,
          strokeWidth: 0,
        },
        labels: {
          fill: Colors.brownGrey,
        },
        cellStartLocation: checked ? 0.41 : 0.45,
        cellEndLocation: checked ? 0.59 : 0.55,
        paddingBottom: 0,
      },
      marginRight: checked ? new am4core.Percent(2) : null,
    },
    {
      id: 'secondCategoryAxis',
      type: 'CategoryAxis',
      dataFields: {
        category: 'monthTitle',
      },
      renderer: {
        minGridDistance: 1,
        grid: {
          location: 0,
          strokeWidth: 0,
        },
        labels: {
          fill: Colors.brownGrey,
        },
        cellStartLocation: 0.41,
        cellEndLocation: 0.59,
        paddingBottom: 0,
      },
      marginLeft: new am4core.Percent(2),
    },
  ]
  const series = [
    {
      xAxis: 'firstCategoryAxis',
      type: 'ColumnSeries',
      data: curYearData,
      dataFields: {
        valueY: 'underAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.cerulean,
      columns: {
        tooltipHTML: checked ? tooltipPrevYear : tooltipCurYear,
      },
      tooltip,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'ColumnSeries',
      data: curYearData,
      dataFields: {
        valueY: 'overAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.rustyRed,
      columns: {
        tooltipHTML: checked ? tooltipPrevYear : tooltipCurYear,
      },
      tooltip,
      stacked: true,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        valueY: 'allowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      noRisers: true,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        valueY: 'lineSeriesTop',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
    {
      xAxis: 'firstCategoryAxis',
      type: 'StepLineSeries',
      data: curYearData,
      dataFields: {
        valueY: 'lineSeriesBottom',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'ColumnSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'underAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.purpleyPink,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'ColumnSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'overAllowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 0,
      fill: Colors.blueGrey,
      columns: {
        tooltipHTML: tooltipPrevYear,
      },
      tooltip,
      stacked: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'StepLineSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'allowance',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      noRisers: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'StepLineSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'lineSeriesTop',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
    {
      xAxis: 'secondCategoryAxis',
      type: 'StepLineSeries',
      data: prevYearData,
      dataFields: {
        valueY: 'lineSeriesBottom',
        categoryX: 'monthTitle',
      },
      strokeWidth: 1,
      stroke: Colors.black,
      strokeOpacity: 0.2,
      noRisers: true,
    },
  ]

  const chart = am4core.createFromConfig(
    {
      data: curYearData,
      xAxes: checked ? xAxes : [xAxes[0]],
      yAxes,
      series: checked ? series : [series[0], series[1], series[2], series[3], series[4]],
      legend: legendHide,
      bottomAxesContainer: {
        layout: 'horizontal',
        reverseOrder: true,
      },
    },
    container,
    am4charts.XYChart,
  )

  if (chart.logo) {
    chart.logo.disabled = true
  }

  return chart
}

const generateData = (traffic: string, months?: number) => {
  let prevYearData: Array<IW7Resp> = []
  let curYearData: Array<IW7Resp> = []

  if (months) {
    for (let step = 1; step <= months; step++) {
      prevYearData.unshift(WidgetData.prevYear[WidgetData.prevYear.length - step])
      curYearData.unshift(WidgetData.curYear[WidgetData.curYear.length - step])
    }
  } else {
    prevYearData = [...WidgetData.prevYear]
    curYearData = [...WidgetData.curYear]
  }

  const generateItem = (item: IW7Resp, i: number) => {
    item.tooltip = generateTooltip(prevYearData, curYearData, i, traffic)
    item.date = new Date(item.year, Number(item.month))
    item.monthTitle = Month[Number(item.month)]
    item.lineSeriesTop = item.allowance + item.tolerance
    item.lineSeriesBottom = item.allowance - item.tolerance
    if (item.allowance + item.tolerance < item.usage) {
      item.underAllowance = item.allowance + item.tolerance
      item.overAllowance = item.usage - (item.allowance + item.tolerance)
    } else {
      item.underAllowance = item.usage
      item.overAllowance = 0
    }
  }

  for (let i = 0; i < prevYearData.length; i++) {
    generateItem(prevYearData[i], i)
    generateItem(curYearData[i], i)
  }

  return {prevYearData, curYearData}
}

const generateDataLastYear = (traffic: string) => {
  let prevYearData: Array<IW7Resp> = [...WidgetData.prevYear]
  let curYearData: Array<IW7Resp> = [...WidgetData.curYear]
  prevYearData.shift()
  curYearData.shift()

  const generateItem = (item: IW7Resp, i: number) => {
    item.tooltip = generateTooltip(prevYearData, curYearData, i, traffic, 'Ø')
    item.lineSeriesTop = item.allowance + item.tolerance
    item.lineSeriesBottom = item.allowance - item.tolerance
    if (item.allowance + item.tolerance < item.usage) {
      item.underAllowance = item.allowance + item.tolerance
      item.overAllowance = item.usage - (item.allowance + item.tolerance)
    } else {
      item.underAllowance = item.usage
      item.overAllowance = 0
    }
  }

  const generateGroups = (data: Array<IW7Resp>): Array<IW7Resp> => {
    const yearData: Array<IW7Resp> = []
    let periodData: IW7Resp
    data.map((item: IW7Resp, index: number) => {
      let monthTitle = `${Month[Number(item.month)]}-${Month[Number(item.month + 2)]}`
      if (!index) {
        const nextMonth = item.month + 2 - (item.month % 3)
        monthTitle =
          item.month === nextMonth
            ? Month[Number(item.month)]
            : `${Month[Number(item.month)]}-${Month[Number(item.month + 2 - (item.month % 3))]}`
      }
      if (item.month % 3 === 0 || index === 0) {
        periodData = {
          year: item.year,
          month: item.month,
          phoneNumbers: item.phoneNumbers,
          usage: item.usage,
          allowance: item.allowance,
          tolerance: item.tolerance,
          monthTitle,
        }
      } else {
        periodData.phoneNumbers += item.phoneNumbers
        periodData.usage += item.usage
      }
      if (item.month % 3 === 2 || data.length - 1 === index) {
        periodData.usage = periodData.usage / ((item.month % 3) + 1 || 1)
        yearData.push(periodData)
      }
    })
    return yearData
  }

  prevYearData = generateGroups(prevYearData)
  curYearData = generateGroups(curYearData)

  for (let i = 0; i < prevYearData.length; i++) {
    generateItem(prevYearData[i], i)
    generateItem(curYearData[i], i)
  }

  return {prevYearData, curYearData}
}

const generateTooltip = (
  prevYearData: Array<IW7Resp>,
  curYearData: Array<IW7Resp>,
  i: number,
  traffic: string,
  dataPrefix: string = '',
): ITooltip => ({
  traffic,
  month: prevYearData[i].monthTitle || Month[Number(prevYearData[i].month)],
  prevYear: String(prevYearData[i].year),
  curYear: String(curYearData[i].year),
  prevPhoneNumbers: `${prevYearData[i].phoneNumbers || 0} St.`,
  curPhoneNumbers: `${curYearData[i].phoneNumbers || 0} St.`,
  prevData: `${dataPrefix} ${replaceSeparator(prevYearData[i].usage)} MiB`,
  curData: `${dataPrefix} ${replaceSeparator(curYearData[i].usage)} MiB`,
  prevAllowance: replaceSeparator(prevYearData[i].allowance),
  curAllowance: replaceSeparator(curYearData[i].allowance),
  prevTolerance: replaceSeparator(prevYearData[i].tolerance),
  curTolerance: replaceSeparator(curYearData[i].tolerance),
  prevDataColor: `${
    prevYearData[i].usage > prevYearData[i].allowance + prevYearData[i].tolerance ? Colors.blueGrey : Colors.purpleyPink
  }`,
  curDataColor: `${
    curYearData[i].usage > curYearData[i].allowance + curYearData[i].tolerance ? Colors.rustyRed : Colors.cerulean
  }`,
})
